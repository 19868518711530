<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <form method="post" @submit.prevent="onSubmit('frmMailGroup')" data-vv-scope="frmMailGroup" novalidate="novalidate">
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-12">
        <div class="card card-fullheight">
          <div class="card-body">
              <div class="row">
                <custom-input
                  v-model="tipoDisparador.nome"
                  name="tipoDisparador.nome"
                  :label="this.$i18n.t('form.mail-group.nome')"
                  type="text"
                  rootClassName="col-sm-12 mb-4"
                  :max-length="125"
                  v-validate="{ required: true }"
                  :error="submitted = errors.first('tipoDisparador.nome')">
                </custom-input>

                <custom-switch
                  :listItems="[{text: 'Ativo', value: 1}]"
                  v-model="tipoDisparador.ativo"
                  name="tipoDisparador.ativo"
                  rootClassName="col-sm-6 mt-2 mb-6"
                  type="checkbox"
                  stateClassName="p-primary"
                  :error="errors.first('tipoDisparador.ativo')">
                </custom-switch>

                <custom-switch
                  :listItems="[{text: 'Verificar Cadência', value: 1}]"
                  v-model="tipoDisparador.verificarCadencia"
                  name="tipoDisparador.verificarCadencia"
                  type="checkbox"
                  rootClassName="col-sm-6 mt-2 mb-6"
                  stateClassName="p-primary"
                  :error="errors.first('tipoDisparador.verificarCadencia')">
                </custom-switch>

              </div>
          </div>
        </div>
      </div>

      <!-- Submit -->
      <div class="col-12">
        <button type="button" class="btn btn-outline-light  pull-right" @click="onSave">
          Salvar
        </button>
        <button type="button" class="btn btn-link btn-sm mt-1 pull-right" @click="onCancel">
          Cancelar
        </button>
      </div>

    </div>
    </form>
  </div>
</template>

<script>
// Components

// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Form
import CustomInput from '@/components/Forms/CustomInput.vue'
import CustomSwitch from '@/components/Forms/CustomSwitch.vue'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// Services
import TaskCampaignTypeService from '@/services/TaskCampaignTypeService'

export default {
  name: 'TaskCampaignTypeForm',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('basic.mail-group.title') + ' - %s'
    }
  },
  data () {
    return {
      formErrors: {},
      isLoading: true,
      fullPage: true,

      //  Selected Values (Form)
      tipoDisparador: {
        nome: null,
        ativo: 1,
        verificarCadencia: 1
      },
      submitted: false

    }
  },
  components: {
    Loading,
    CustomInput,
    CustomSwitch
  },
  // Apply filters to local filter
  beforeMount () {

  },
  mounted () {
    // this.$store.dispatch('SetShowFilter', true)
    // this.$store.dispatch('setShowSearch', false)
  },
  created () {
    let _this = this
    // Load basilares data

    // If is edit populate values
    let id = this.$route.params.id

    if (id) {
      TaskCampaignTypeService.getTaskCampaignType(id).then(entity => {
        _this.tipoDisparador = entity.data

        // Status
        if (entity.data.ativo === true) {
          _this.tipoDisparador.ativo = 1
        } else {
          _this.tipoDisparador.ativo = 2
        }

        // Verificar Cadência
        if (entity.data.verificar_cadencia === true) {
          _this.tipoDisparador.verificarCadencia = 1
        } else {
          _this.tipoDisparador.verificarCadencia = 2
        }
      }).finally(() => {
        _this.isLoading = false
      })
    } else {
      _this.isLoading = false
    }
  },
  computed: {

  },
  methods: {
    onCancel (e) {
      var _this = this
      _this.$router.push({ name: 'TaskCampaignTypeIndex' })
    },
    displayErrors (errors) {
      let formatedErrors = util.getFormErrors(errors)
      this.formErrors = formatedErrors
    },
    showNumber (number, thousandsSep, defaultValue) {
      return util.showNumber(number, thousandsSep, defaultValue)
    },
    onSave (e) {
      var _this = this
      this.submitted = true
      _this.$validator.validate().then(valid => {
        if (valid) {
          _this.isLoading = true

          let tipoDisparadorData = {
            nome: _this.tipoDisparador.nome,
            ativo: (parseInt(_this.tipoDisparador.ativo) === 1),
            verificarCadencia: (parseInt(_this.tipoDisparador.verificarCadencia) === 1)
          }

          let id = this.$route.params.id

          if (id) {
            TaskCampaignTypeService.editTaskCampaignType(id, tipoDisparadorData).then(response => {
              _this.$router.push({ name: 'TaskCampaignTypeIndex' })
            }).catch(err => {
              if (err.code === 400) {
                _this.displayErrors(err.errors)
              }
              _this.isLoading = false
            })
          } else {
            TaskCampaignTypeService.newTaskCampaignType(tipoDisparadorData).then(response => {
              _this.$router.push({ name: 'TaskCampaignTypeIndex' })
            }).catch(() => {
              _this.isLoading = false
            })
          }
        }
      })
    }
  }
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .card-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md-form-control {
    height: 30px;
  }

  .v-select.md-form-control {
    height: 35px;
  }
</style>
